<template>
  <div v-if="isAdmin || checkAccess('shanto-it-payments')">
    <report-list-page
        page-title="Shanto IT Payments Reports"
        page-icon="mdi-cash-multiple"
        :descending="true"
        :headers="headers"
        state-end-point="report.payments"
        :actions="actions"
        :show-component="showComponent"
        :show-grand-total="false"
        :show-payment-footer="true"
        sort="invoiceId"
        item-key="id"
    />
  </div>
</template>

<script>
import ReportListPage from "../../components/ReportListPage";
import IsAuthenticated from "../../middleware/IsAuthenticated";
export default {
  name: "ShantoITPayments",
  mixins: [IsAuthenticated],
  components: {ReportListPage},
  data() {
    return {
      headers: [
        {
          text: "Invoice No",
          value: "invoiceId",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Total Amount",
          value: "total",
        },
        {
          text: "Payment Fees",
          value: "paymentFees",
        },
        {
          text: "Gross Total",
          value: "grossTotal",
        },
      ],
      actions: {
        load: "loadShantoIT",
        pdfURL: '/payment/reports/shanto-it/pdf',
        nameOfPDF: 'Shanto-IT-Firm-Payment-Report',
      },
      showComponent: {
        isUser: false,
        isStatus: false,
        isMethod: false,
        isEmail: false,
        isSite: false,
        isPDF: true,
        isDate: true
      }
    }
  }
}
</script>

<style scoped>

</style>